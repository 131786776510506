module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5PCZR7C","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wall-to-Wall Occasional Cards","short_name":"WTW Occasional Cards","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"minimal-ui","icon":"/opt/build/repo/src/assets/manifest-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ff29d388ee71ec998b8d906ad4105e87"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
