/**
 * This module is imported in the following files:
 *
 *   - /gatsby-browser.js
 *   - /gatsby-ssr.js
 *
 * All exports are re-exported in the gatsby-* files allowing access to the
 * Gatsby Browser and SSR APIs.
 *
 * @see https://www.gatsbyjs.org/docs/browser-apis/
 * @see https://www.gatsbyjs.org/docs/ssr-apis/
 */

import 'minireset.css'
import '@reach/skip-nav/styles.css'
import '@walltowall/siamese/dist/siamese.cjs.development.css'
import 'fontsource-barlow/400-normal.css'
import 'fontsource-barlow/500-normal.css'

import './index.css'

import * as React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { TreatProvider } from 'react-treat'
import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
import { RecoilRoot } from 'recoil'

import { DebugProvider } from './hooks/useDebug'
import { theme } from './theme.treat'
import { dndTouchBackendOptions } from './constants'

export const wrapRootElement: NonNullable<GatsbyBrowser['wrapRootElement']> = ({
  element,
}) => (
  <DebugProvider>
    <TreatProvider theme={theme}>
      <DndProvider backend={TouchBackend} options={dndTouchBackendOptions}>
        <RecoilRoot>{element}</RecoilRoot>
      </DndProvider>
    </TreatProvider>
  </DebugProvider>
)
