import { TouchBackendOptions } from 'react-dnd-touch-backend/lib/interfaces'

import AssetCardBalloonsUrl from './assets/card-balloons.png'
import AssetCardChampagneUrl from './assets/card-champagne.png'
import AssetCardDumpsterUrl from './assets/card-dumpster.png'
import AssetCardFlowersUrl from './assets/card-flowers.png'
import AssetCardRollerCoasterUrl from './assets/card-rollercoaster.png'
import AssetCardSweatpantsUrl from './assets/card-sweatpants.png'
import AssetCardBalloonsThumbUrl from './assets/card-balloons-thumb.png'
import AssetCardChampagneThumbUrl from './assets/card-champagne-thumb.png'
import AssetCardDumpsterThumbUrl from './assets/card-dumpster-thumb.png'
import AssetCardFlowersThumbUrl from './assets/card-flowers-thumb.png'
import AssetCardRollerCoasterThumbUrl from './assets/card-rollercoaster-thumb.png'
import AssetCardSweatpantsThumbUrl from './assets/card-sweatpants-thumb.png'

export const __SERVER__ = typeof window === 'undefined'
export const __BROWSER__ = !__SERVER__

export const dndTouchBackendOptions = {
  enableMouseEvents: true,
  delay: 0,
} as TouchBackendOptions

export const SITE_URL = 'https://occasionalcards.walltowall.com'

export const WW_INSTAGRAM_URL = 'http://instagram.com/walltowall'
export const WW_TWITTER_URL = 'https://twitter.com/walltowall'
export const WW_FACEBOOK_URL = 'https://www.facebook.com/walltowallstudios'
export const WW_SHOP_URL = 'https://shop.walltowall.com/'

export const HTML2CANVAS_CARD_ID = 'card'
export const HTML2CANVAS_OG_IMAGE_ID = 'og-image'

export const BASE_CARD_SIZE_PX = 350

export const CATEGORY_NICE_NAME_MAP = {
  'add-ons': 'Add Ons',
  adjectives: 'Adjectives',
  closers: 'Closers',
  greetings: 'Greetings',
  'little-words': 'Little Words',
  occasions: 'Occasions',
}

export const CATEGORIES_ORDER = [
  'greetings',
  'little-words',
  'adjectives',
  'occasions',
  'closers',
  'add-ons',
]

export const CARD_IMAGE_URLS = [
  AssetCardBalloonsUrl,
  AssetCardChampagneUrl,
  AssetCardDumpsterUrl,
  AssetCardFlowersUrl,
  AssetCardRollerCoasterUrl,
  AssetCardSweatpantsUrl,
]

export const CARD_THUMB_IMAGE_URLS = [
  AssetCardBalloonsThumbUrl,
  AssetCardChampagneThumbUrl,
  AssetCardDumpsterThumbUrl,
  AssetCardFlowersThumbUrl,
  AssetCardRollerCoasterThumbUrl,
  AssetCardSweatpantsThumbUrl,
]

export const STICKER_ROTATION_RANGE = [-4, 4] as const
